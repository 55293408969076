import React from 'react'

import { Divider, Grid } from 'semantic-ui-react'

import PrimaryCard from 'design-system/components/cards/primary-card'
import { Section } from 'design-system/components/guide/utils'
import { resourcesUrls } from 'views/utils'

const WhatWeOffer = () => {
  const catalogs = [
    {
      image: 'upgrade-pack.webp',
      title: 'Product Catalog',
      url: resourcesUrls.upgradePack,
    },
  ]

  return (
    <Section id="what-we-offer">
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3 className="gray">What we offer</h3>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <Grid.Row className="row-gap" stretched>
          {catalogs.map((c) => (
            <Grid.Column
              key={c.url}
              largeScreen={4}
              computer={5}
              tablet={8}
              mobile={16}
            >
              <PrimaryCard
                image={`${process.env.GATSBY_CDN_ROOT}/images/app/${c.image}`}
                onClick={() => window.open(c.url)}
                title={c.title}
              />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </Section>
  )
}

export default WhatWeOffer
