import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import useProject from 'context/project/use'
import PrimaryCard from 'design-system/components/cards/primary-card'
import { Drawer } from 'design-system/components/drawer/drawer'
import AppLayout from 'design-system/containers/app-layout'
import { resourcesUrls } from 'views/utils'

interface CatalogProps {
  image: string
  title: string
  url: string
}

const Module = (_: RouteComponentProps<{ project_id: string }>) => {
  useEffect(() => {
    document.body.scrollTo({ top: 0 })
  })

  const { paymentProjectDepositPaid, isTradeProject } = useProject()

  const kitchenCatalogs: CatalogProps[] = [
    {
      image: 'catalogs-kickoff.webp',
      title: 'Visual Glossary',
      url: resourcesUrls.visualGlossary,
    },
    ...(paymentProjectDepositPaid || isTradeProject
      ? [
          {
            image: 'upgrade-pack.webp',
            title: 'Product Catalog',
            url: resourcesUrls.upgradePack,
          },
        ]
      : []),
    {
      image: 'catalogs-quality.webp',
      title: 'Technical Guide',
      url: resourcesUrls.technicalGuide,
    },
    {
      image: 'catalogs-2022.webp',
      title: 'Why FORM',
      url: resourcesUrls.whyFORM,
    },
  ]

  const drawerContent = (catalogs: CatalogProps[]) => (
    <Grid style={{ marginTop: '5px' }}>
      <Grid.Row columns={4} stretched style={{ rowGap: '20px' }}>
        {catalogs.map((c, key) => (
          <Grid.Column key={key} computer={4} tablet={5} mobile={16}>
            <PrimaryCard
              image={`${process.env.GATSBY_CDN_ROOT}/images/app/${c.image}`}
              onClick={() => window.open(c.url)}
              title={c.title}
            />
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  )

  return (
    <AppLayout>
      <h3 style={{ textAlign: 'center' }}>Catalogs</h3>
      <Drawer
        content={drawerContent(kitchenCatalogs)}
        defaultOpen
        title="Kitchen design"
      />
    </AppLayout>
  )
}

export default Module
