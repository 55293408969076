import React from 'react'

import { Grid } from 'semantic-ui-react'

import useProject from 'context/project/use'
import useGuideUrls from 'context/project/use-guide-urls'
import PrimaryCard from 'design-system/components/cards/primary-card'
import {
  Section,
  UnderlineSubtitle,
} from 'design-system/components/guide/utils'
import { resourcesUrls } from 'views/utils'

const UsefulLinks = () => {
  const { isTradeProject } = useProject()
  const { ExtraRoom } = useGuideUrls()

  return (
    <Section id="useful-links">
      <Grid centered>
        <Grid.Row>
          <Grid.Column>
            <UnderlineSubtitle>Useful links</UnderlineSubtitle>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered className="row-gap">
          {isTradeProject ? (
            <>
              <Grid.Column largeScreen={4} computer={5} tablet={7} mobile={16}>
                <PrimaryCard
                  image={`${process.env.GATSBY_CDN_ROOT}/images/app/trade-deck.png`}
                  onClick={() => window.open(resourcesUrls.tradeDeck)}
                  title="Trade Deck"
                />
              </Grid.Column>
              <Grid.Column largeScreen={4} computer={5} tablet={7} mobile={16}>
                <PrimaryCard
                  image={`${process.env.GATSBY_CDN_ROOT}/images/app/front-handles-guide.webp`}
                  onClick={() => window.open(resourcesUrls.frontsAndHandles)}
                  title="Fronts and Handles"
                />
              </Grid.Column>
            </>
          ) : (
            <Grid.Column largeScreen={4} computer={5} tablet={7} mobile={16}>
              <PrimaryCard
                image={`${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/extra-room/extra-room.webp`}
                onClick={() => window.open(ExtraRoom)}
                title="Extra Rooms Guide"
              />
            </Grid.Column>
          )}
          <Grid.Column largeScreen={4} computer={5} tablet={7} mobile={16}>
            <PrimaryCard
              image={`${process.env.GATSBY_CDN_ROOT}/images/app/upgrade-pack.webp`}
              onClick={() => window.open(resourcesUrls.upgradePack)}
              title="Product Catalog"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Section>
  )
}

export default UsefulLinks
